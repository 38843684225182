html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3 {
  margin: 10px 0 10px 0;
  letter-spacing: -1.5px;
}

h1 {
  font-size: 28px;
}

.cover-page {
    height: 95vh;
    min-height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

@-webkit-keyframes logo-animation {
    from {
        -webkit-transform: perspective(800px) rotateX(90deg);
                transform: perspective(800px) rotateX(90deg);
    }
    to {
        -webkit-transform: perspective(800px) rotateX(10deg);
                transform: perspective(800px) rotateX(10deg);
    }
}

@keyframes logo-animation {
    from {
        -webkit-transform: perspective(800px) rotateX(90deg);
                transform: perspective(800px) rotateX(90deg);
    }
    to {
        -webkit-transform: perspective(800px) rotateX(10deg);
                transform: perspective(800px) rotateX(10deg);
    }
}

.cover-card {
    text-align: center;
    padding: 40px 20px;
    margin: 20px;
    background-color: #fff;
    -webkit-animation-name: logo-animation;
            animation-name: logo-animation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-transform: perspective(800px) rotateX(10deg);
            transform: perspective(800px) rotateX(10deg);
    box-shadow: 0 10px 15px #ddd;
    width: 70%;
    max-width: 400px;
    border-bottom: 5px solid #eee;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
}

.cover-card:hover {
  -webkit-transform: perspective(800px) rotateX(2deg) scale(1.05);
          transform: perspective(800px) rotateX(2deg) scale(1.05);
}

.cover-card.hidden {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
}

.text-container > h1 {
    font-size: 42px;
    font-weight: normal;
    color: #444;
    font-family: 'Pathway Gothic One', sans-serif;
    margin: 0;
}

.text-container > span {
    font-size: 12px;
}

@media (min-width: 500px) {
    .text-container > h1 {
        font-size: 48px;
    }
    .text-container > span {
        font-size: 16px;
    }
}

.scroll-container {
    color: #888;
}

.container {
    max-width: 1050px;
    margin: 50px auto;
}

.projects-container {
    text-align: center;
}

.projects-header {
  padding: 0 30px;
}

.projects-header > h1 {
    font-size: 32px;
    display: inline;
    border-bottom: 2px solid #000;
}

.projects-header > p {
    font-size: 18px;
}

.about {
  max-width: 600px;
  margin: 0 auto 100px auto;
  padding: 30px;
}

.about p {
  font-size: 20px;
  margin-bottom: 50px;
}

.about h1 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: normal;
}

.profile-photo {
  -webkit-clip-path: circle(35%);
          clip-path: circle(35%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 100%;
  margin-bottom: 50px;
}

.profile-photo:hover {
  -webkit-clip-path: circle(38%);
          clip-path: circle(38%);
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.profile-photo img {
  box-shadow: none;
  max-width: 400px;
  width: 100%;
  margin: auto;
}

@media (max-width: 600px) {
  .profile-photo img {
    width: 70%;
  }
}

.btn {
  background-color: #fff;
  border: solid #eee;
  border-width: 0 0 4px 0;
  box-shadow: 0 2px 8px #ccc;
  padding: 12px 24px;
  font-size: 18px;
  color: #555;
  margin: 5px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.btn:hover {
  background-color: #f5f5f5;
  border-color: #e5e5e5;
  color: #333;
}

.link-btn {
  text-decoration: none;
}

@media(max-width: 350px) {
  .btn-group .btn {
    font-size: 15px;
  }
}

.project {
    text-align: center;
    border-radius: 3px;
    padding: 10px 30px 75px 30px;
}

.project:nth-child(even) {
  background-color: #f6f6f6;
  box-shadow: 0 0 25px #e8e8e8;
}

.project:nth-child(even) img {
  box-shadow: 0 5px 15px #ddd;
}

.project-header {
  margin: 30px auto;
}

.project-header h1 {
    margin: 0;
    font-size: 32px;
}

.project-header span {
    font-size: 20px;
    color: #555;
}

.project-body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    max-width: 1100px;
    margin: auto;
}

.project-body > div {
    margin-top: 50px;
}

img {
    width: 100%;
    box-shadow: 0 5px 15px #aaa;
    border-radius: 3px;
}

img.no-shadow {
    box-shadow: none !important;
}

video {
    box-shadow: 0 5px 15px #aaa;
    border-radius: 3px;
    opacity: 0.8;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

video:hover {
    opacity: 1;
}

.project-content {
    padding: 0;
    max-width: 430px;
}

.project-content p {
   font-size: 18px;
   text-align: justify;
   margin-bottom: 30px;
}

.project-image {
  max-width: 350px;
  padding: 0 50px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

@media(min-width: 400px) {
  .project-content {
    padding: 20px;
  }
}

@media(max-width: 1000px) {
  .project-body {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .project-image {
    padding: 0 20px;
    max-width: 450px;
  }
  .project-content {
    max-width: 500px;
  }
}

.btn.btn-github {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  box-shadow: none;
  width: 60px;
  margin-left: 2px;
}

