.cover-page {
    height: 95vh;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes logo-animation {
    from {
        transform: perspective(800px) rotateX(90deg);
    }
    to {
        transform: perspective(800px) rotateX(10deg);
    }
}

.cover-card {
    text-align: center;
    padding: 40px 20px;
    margin: 20px;
    background-color: #fff;
    animation-name: logo-animation;
    animation-duration: 1s;
    transform: perspective(800px) rotateX(10deg);
    box-shadow: 0 10px 15px #ddd;
    width: 70%;
    max-width: 400px;
    border-bottom: 5px solid #eee;
    transition: transform .3s ease;
}

.cover-card:hover {
  transform: perspective(800px) rotateX(2deg) scale(1.05);
}

.cover-card.hidden {
    transform: scale(0);
    opacity: 0;
}

.text-container > h1 {
    font-size: 42px;
    font-weight: normal;
    color: #444;
    font-family: 'Pathway Gothic One', sans-serif;
    margin: 0;
}

.text-container > span {
    font-size: 12px;
}

@media (min-width: 500px) {
    .text-container > h1 {
        font-size: 48px;
    }
    .text-container > span {
        font-size: 16px;
    }
}

.scroll-container {
    color: #888;
}
